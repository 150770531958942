@charset "utf-8";
@import "node_modules/bulma/sass/utilities/initial-variables";
@import "node_modules/bulma/sass/utilities/functions";

// Bulma

$orange: #e14d43;
$grey-darker: #121212;
$grey-dark: #1e1e1e;

$scheme-main: $grey-darker;
$border: $grey;

$text: $white;
$text-light: $grey-lightest;
$text-strong: $grey-lighter;

$link: $orange;
$link-hover: $yellow;
$link-hover-border: $yellow;
$link-active: $grey-lighter;
$link-active-border: $yellow;
$link-focus: $yellow;
$link-focus-border: $grey-lighter;

$button-background-color: $grey-dark;
$button-hover-color: $yellow;
$button-hover-border-color: $yellow;
$button-focus-color: $text-light;
$button-focus-border-color: $orange;
$button-active-color: $white;

$footer-background-color: $grey-dark;
$footer-padding: 1rem 2rem;

// Layout

$section-padding: 1.5rem;

// Graph

$graph-background-color: $footer-background-color;

$node-background-color: #3c3c3c;
$node-border-color: #1a1a1a;

$connection-color: #131313;
$connection-event-color: #ae0000;
$connection-property-color: #005a9f;
$connection-link-color: #ffc62f;

$node-foreign-partition-color: #371e13;

$node-type-interface-descriptor-data-color: #301d30;
